import { Dialog, Button, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton, Menu, MenuItem, Box } from '@material-ui/core'
import { useCreateActionContext } from "../../contexts/CreateActionContext"
import { useSnackbar } from 'notistack';
import ActionForm from './ActionForm';
import useCreateAction from '../../hooks/mutations/useCreateAction';
import { WifiTethering as OnTheSpotIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import { useState } from 'react';
import QRCodeDialog from './QRCodeDialog';
import qrCodeService from "../../services/qrCodeService";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    csvLink: {
        visibility: "hidden",
    },
    onTheSpotIcon: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center', 
    },
    headerText: {
        marginLeft: theme.spacing(1),
    },
}));

export default function CreateActionDialog({ isModule, module, open, onCancel, closeActionCreateWithoutPrompt }) {
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();
    const { 
        actionDetails, 
        reset,
    } = useCreateActionContext()

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const isQrMenuOpen = Boolean(menuAnchorEl);
    const [qrCodeDialogOpen, setQRCodeDialogOpen] = useState(false);
    const [qrCodeByteArray, setQRCodeByteArray] = useState(null);
    const { selectedSite, activeAppId, appSiteIds, selectedAccount } = useSelector(
        (state) => state.account
    );
    /* Open new QR code dialog for form */
    const generateQRCode = async () => {   
        let url = window.location.href + "/report-issue?externalId=" + selectedAccount.account.externalId;
        let response = await qrCodeService.createQRCode(url);

        setQRCodeByteArray(response);
        setQRCodeDialogOpen(true);
        closeActionCreateWithoutPrompt();
    };
    const handleQRCodeDialogClose = () => {
        setQRCodeDialogOpen(false);
    };
    const handleMenuToggle = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };


    function handleCancel() {
        reset();
        onCancel();
    }

    const createAction = useCreateAction()

    function handleCreateAction(e) {
        e.preventDefault()

        createAction.mutate({
            ...actionDetails,
        })
         reset()
        enqueueSnackbar('On-the-Spot Action created successfully', { variant: 'success' })
    }

    return (
         <>
        <Dialog
            open={open}
            onClose={onCancel}
        >  
            <form
                onSubmit={(e) => handleCreateAction(e)}
                onReset={handleCancel}
            >
                <DialogTitle>
                    <div className={classes.header}>
                        <Box className={classes.headerContent}>
                            <OnTheSpotIcon />
                            <Typography className={classes.headerText}>
                                Add On-the-Spot Action
                            </Typography>
                        </Box>
                        <IconButton
                            aria-label="QR menu"
                            aria-controls="qr-menu"
                            aria-haspopup="true"
                            onClick={handleMenuToggle}
                            color="inherit"
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </div> 
                </DialogTitle>
                  <DialogContent>
                    <ActionForm
                        isModule={isModule}
                        module={module.module}
                    />
                  </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color="primary"
                        variant="text"
                    >
                        Save
                    </Button>
                </DialogActions>
                </form>
                <Menu
                    id="qr-menu"
                    anchorEl={menuAnchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={isQrMenuOpen}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                >
                    <MenuItem onClick={generateQRCode}>
                        Generate QR Code
                    </MenuItem>
                </Menu>
            </Dialog>
            <QRCodeDialog open={qrCodeDialogOpen} onClose={handleQRCodeDialogClose} image={qrCodeByteArray} headerText="Scan me!" subText="To log an issue you have encountered" fileName="logAnIssue.png" />
        </>
    )
}