import {
    Description as TextIcon,
    PictureAsPdf as PdfIcon,
} from "@material-ui/icons";

export const iconFromContentType = (contentType) => {
    switch (contentType) {
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/msword":
            return <TextIcon />;
        case "application/pdf":
            return <PdfIcon />
        default:
            return null;
    }
};
