import FinancierFont from "./fonts/financier-regular.woff2";
import SailecFont from "./fonts/sailec-regular.otf";

const heading = {
    fontFamily: ["Financier", "Times New Roman", "serif"].join(","),
};

const Financier = {
    fontFamily: "Financier",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
    local('Financier'),
    url(${FinancierFont}) format('woff2')
  `,
};

const Sailec = {
    fontFamily: "Sailec",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
    local('Sailec'),
    url(${SailecFont}) format('opentype')
  `,
};

export const baseTheme = {
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": [Financier, Sailec],
                html: {
                    WebkitFontSmoothing: "auto",
                    height: "100%",
                },
                body: {
                    height: "100%",
                    color: "#12054D",
                    "& th, td, li": {
                        color: "#12054D !important",
                    },
                },
                ".MuiTablePagination-root": {
                    color: "#12054D !important",
                },
            },
        },
    },
    typography: {
        fontFamily: ["Sailec", "Arial", "sans-serif"].join(","),
        h1: heading,
        h2: heading,
        h3: heading,
        h4: heading,
        h5: heading,
        h6: heading,
        button: {
            textTransform: "none",
        },
    },
    palette: {
        text: {
            primary: "#12054D",
        },
        primary: {
            light: "#5C45C7",
            mainOpaque: "#12054D58",
            main: "#12054D", // here
        },
        secondary: {
            light: "#AB96FA",
            mainOpaque: "#7A5CF033",
            main: "#5848c9",//here
            dark: "#5C45C7",
        },
        warningYellow: '#FFA500',
        hazardOrange: '#FF8C00',
        successGreen: '#2E7D32',
        errorRed: '#B00020',
        neutralGrey: '#716994',
        title: {
            background: '#f4eeff',
            text: '#483795'
        },        
    },
}

export const altTheme = {
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        text: {
            primary: "#292f36"
        },
        primary: {
            ...baseTheme.palette.primary,
            main: "#292f36"
        },
        secondary: {
            ...baseTheme.palette.secondary,
            mainOpaque: "#bb0058",
            main: "#bb0058",
        },
        title: {
            background: "#fde9f1",
            text: "#bb0058"
        }
    }    
}

