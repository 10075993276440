import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import knowledgeBankService from "../../services/knowledgeBankService";

const useKnowledgeBank = () =>
    useQuery(
        [queryKeys.knowledgeBank],
        knowledgeBankService.getData,
        {
            staleTime: Infinity
        }
    )

export default useKnowledgeBank;