import {
    makeStyles,
    Typography,
    Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    epic: {
        width: "100%",
        padding: '30px 100px',
        background: theme.palette.title.background,
        color: theme.palette.title.text,
        borderRadius: '10px'
    },
}));

const Epic = ({ title, main, sub, buttons }) => {
    const classes = useStyles();    

    return (
        <Box display="flex" flexDirection="column" className={classes.epic} alignItems="center" gridGap="20px" textAlign="center">
            <Typography style={{ fontSize: '2.5em', fontWeight: 'bold' }}>{title}</Typography>
            <Typography variant="body1">{main}</Typography>
            {sub && <Typography variant="body2" style={{ fontStyle: 'italic' }}>{sub}</Typography>}
            {buttons &&
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                    gridGap="5px"
                >
                    {buttons.map((button, i) => <Box key={i}>{button}</Box>)}
                </Box>
            }
        </Box>
    );
}

export default Epic;
