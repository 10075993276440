import { useState } from "react";
import { Link } from "react-router-dom";
import {
    Typography,
    Button,
    Box,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const KnowledgeBankBar = () => {  

    const [search, setSearch] = useState("");
    return (
        <Box display="flex" alignItems="center" gridGap="5px" style={{ width: '100%' }}>
            <Typography>HR Knowledge Base</Typography>
            <Button component={Link} to="/knowledge">Home</Button>
            <Button component={Link} to="/knowledge/categories">Categories</Button>
            <Button component={Link} to="/knowledge/events">Events</Button>
            <Box flexGrow={1}></Box>
            <TextField
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                size="small"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Button style={{height: '40px'}} variant="outlined" component={Link} to="/knowledge">Contact Us</Button>
        </Box>
    );
}

export default KnowledgeBankBar;
