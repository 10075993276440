import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "../contexts/authContext";
import { baseTheme, altTheme } from "../theme";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import reactQueryUtils from "../utils/reactQueryUtils";
import { Provider } from "react-redux";
import store from "../redux/store";
import { isHRS } from "../constants/siteConfig";

const theme = createTheme(isHRS ? altTheme : baseTheme);

const AppProviders = ({ children }) => {
    return (
        <Router>
            <Provider store={store}>
                <QueryClientProvider client={reactQueryUtils.client}>
                    <AuthProvider>
                        <ThemeProvider theme={theme}>{children}</ThemeProvider>
                        <ReactQueryDevtools />
                    </AuthProvider>
                </QueryClientProvider>
            </Provider>
        </Router>
    );
};

export default AppProviders;
