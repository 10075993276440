import React, { useState, useCallback, useEffect } from "react";
import { Container, makeStyles, TextField, Box, Typography, Divider, IconButton } from "@material-ui/core";
import ResponsiveSelect from "../../../../components/ui/ResponsiveSelect";
import Layout from '../layout/Layout';
import { useLocation } from 'react-router-dom';
import useNonUserAccountAppSites from "../../../hooks/queries/useNonUserAccountAppSites";
import PageHeader from '../layout/PageHeader';
import FormSubheader from '../layout/FormSubHeader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
    },
    mainContent: {
        flex: 1,
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(5),
    },
    formContent: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    datePicker: {
        margin: 0,
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    fileUploadContainer: {
        position: 'relative', 
        border: '2px dashed #ccc',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3),
        textAlign: 'center',
        backgroundColor: '#fafafa',
        cursor: 'pointer',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2), 
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: '#f0f0f0',
        },
    },
    attachmentLabel: {
        position: 'absolute',
        top: -12,
        left: 10,
        backgroundColor: '#fafafa',
        padding: '0 4px',
        color: 'rgba(0, 0, 0, 0.54)', 
    },
    fileInput: {
        display: 'none',
    },
    fileList: {
        marginTop: theme.spacing(2),
    },
    fileItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: '#f5f5f5',
        borderRadius: theme.spacing(0.5),
    },
    removeButton: {
        minWidth: 'auto',  
        padding: theme.spacing(0.5),  
        color: theme.palette.error.main,
    },
}));

function ActionDetails({ onSubmit, onBack, formData, setFormData, onTabChange, currentStep, totalSteps }) {
    const classes = useStyles();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const externalId = queryParams.get('externalId');  
    const nextButtonText = currentStep === totalSteps ? 'Submit' : 'Next';
    
    const { data: accountSitesData } = useNonUserAccountAppSites({
        parentExternalId: externalId
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errors, setErrors] = useState({}); 

    const siteOptions = React.useMemo(() => {
        if (!Array.isArray(accountSitesData)) return [];

        const options = [];

        accountSitesData.forEach(root => {
            options.push({
                value: root.rootExternalId,
                label: root.name
            });

            root.childAccounts.forEach(child => {
                options.push({
                    value: child.externalId,
                    label: child.name 
                });
            });
        });

        return options;
    }, [accountSitesData]);

    const handleChange = (e) => {
        const { name, value } = e.target;      
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }

    const validateForm = useCallback(() => {
        const newErrors = {};

        if (!formData.description?.trim()) {
            newErrors.description = 'Description is required';
        }
        if (!formData.siteExternalId) {
            newErrors.siteExternalId = 'Site selection is required';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData]);

    const handleNext = () => {
        if (validateForm()) {
            const updatedData = {
                ...formData,                          
            };
            if (typeof onSubmit === 'function') {
                onSubmit(updatedData);
            }
        }
    };

    const onFilesChanged = (files) => {
        const filesArray = Array.from(files);
        setSelectedFiles(prev => [...prev, ...filesArray]);
        setFormData(prev => ({
            ...prev,
            attachments: [...(prev.attachments || []), ...filesArray]
        }));
    };

    const removeFile = (indexToRemove) => {
        setSelectedFiles(prev => prev.filter((_, index) => index !== indexToRemove));
        setFormData(prev => ({
            ...prev,
            attachments: (prev.attachments || []).filter((_, index) => index !== indexToRemove)
        }));
    };
        
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        onFilesChanged(files);
    };

    useEffect(() => {
        if (formData.siteExternalId === undefined) {
            setFormData(prev => ({
                ...prev,
                siteExternalId: ''
            }));
        }
    }, []);

    return (
        <Layout
            onNext={handleNext}
            onBack={onBack}
            showBackButton={true}
            nextButtonText={nextButtonText}        
        >
            <Container maxWidth="md">
                <PageHeader title="Report an Issue" subtitle="Use this form to report issues that have occurred and help us maintain a safe environment." />
                <Box >
                    <Divider />
                </Box>
                <div className={classes.formContent}>
                    <FormSubheader 
                        title="Issue Details"
                        helperText=""
                    />
                    <ResponsiveSelect
                        fullWidth
                        required
                        className={classes.formField}
                        label="Site"
                        options={siteOptions.map(option => ({
                            value: option.value,
                            label: option.label,
                            disabled: option.disabled
                        }))}
                        value={formData.siteExternalId}
                        onChange={handleChange}
                        name="siteExternalId"
                        optionValueKey="value"
                        optionLabelKey="label"
                        error={!!errors.siteExternalId}
                        helperText={errors.siteExternalId}
                    />               
                    
                    <TextField
                        required
                        variant="outlined"
                        fullWidth
                        label="Description"
                        value={formData.description}
                        multiline={true}
                        rows={5}
                        onChange={handleChange}
                        name="description"
                        className={classes.formField}
                        error={!!errors.description}
                        helperText={errors.description}
                    />
                    
                    <input
                        type="file"
                        multiple
                        className={classes.fileInput}
                        id="file-input"
                        onChange={(e) => onFilesChanged(e.target.files)}
                        accept="image/*,.pdf,.doc,.docx"
                    />
                    <label htmlFor="file-input">
                        <div 
                            className={classes.fileUploadContainer}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <Typography variant="body2" className={classes.attachmentLabel}>
                                Attachments
                            </Typography>
                            <CloudUploadIcon style={{ fontSize: 48, color: '#666', marginBottom: 8 }} />
                            <Typography variant="body1" gutterBottom>
                                Drag and drop files here or click to select
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Supported formats: Images, PDF, DOC, DOCX
                            </Typography>                          
                        </div>
                    </label>
                    {selectedFiles.length > 0 && (
                        <div className={classes.fileList}>
                            {selectedFiles.map((file, index) => (
                                <div key={index} className={classes.fileItem}>
                                    <Typography variant="body2">{file.name}</Typography>
                                    <IconButton
                                        size="small"
                                        className={classes.removeButton}
                                        onClick={() => removeFile(index)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </Layout>
    );
}

export default ActionDetails; 