import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { ReactComponent as WorknestLogo } from "../images/WorkNest_Purple_Negative_RGB.svg";
import { ReactComponent as HRSolutionsLogo } from "../images/HR-Solutions-Logo-2023.svg";
import useFeatureFlag from "../hooks/useFeatureFlag";
import { isHRS } from "../constants/siteConfig";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        padding: theme.spacing(3),
    },
    copyright: {
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(2),
        },
    },
    logoWrapper: {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "200px",
        marginLeft: "auto",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    logo: {
        width: "100%",
        maxHeight: "64px"
    },
    navItemsList: {
        listStyle: "none",
        padding: 0,
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
        },
    },
    navItem: {
        display: "inline-block",
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
        },
    },
    navLink: {
        color: "#fff",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const Footer = () => {
    const classes = useStyles();
    const { data: isTrustCentreEnabled } = useFeatureFlag("TrustCentre");

    return (
        <footer className={classes.footer}>
            <Container className={classes.container} maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} sm={9}>
                        <nav>
                            <ul className={classes.navItemsList}>
                                {isTrustCentreEnabled ? (
                                    <>
                                        <li className={classes.navItem}>
                                            <Link to="/trustcentre" className={classes.navLink}>
                                                Trust Centre
                                            </Link>
                                        </li>
                                        <li className={classes.navItem}>
                                            <Link to="/trustcentre/privacy" className={classes.navLink}>
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className={classes.navItem}>
                                            <Link to="/processing" className={classes.navLink}>
                                                Processing Policy
                                            </Link>
                                        </li>
                                        <li className={classes.navItem}>
                                            <Link to="/privacy" className={classes.navLink}>
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </nav>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className={classes.logoWrapper}>
                            {isHRS ? <HRSolutionsLogo className={classes.logo} /> : <WorknestLogo className={classes.logo} />}
                        </div>
                    </Grid>
                    <Grid className={classes.copyright} item xs={12}>
                        &copy; WorkNest {new Date().getFullYear()}
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;
