import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import actionService from "../../services/actionService";

const useSiteCurrentActions = ({
    externalIds,
    forUser,
    moduleArea,
    fromDate,
    toDate,
    pageNum,
    pageSize,
    showCompleted,
    auditType,
    rating,
    sortOrder,
    orderByColumn,
    assignees,
    filteredText,
    nonUser = false
}) =>
    useQuery(
        [
            nonUser ? queryKeys.nonUserActions : queryKeys.currentActions,
            externalIds?.join(","),
            forUser,
            moduleArea !== null ? String(moduleArea) : null,
            fromDate,
            toDate,
            pageNum,
            pageSize,
            showCompleted,
            auditType,
            rating !== null ? String(rating) : null,
            sortOrder,
            orderByColumn,
            assignees?.join(","),
            filteredText && filteredText.length > 3 ? filteredText : undefined,
            nonUser
        ].filter(Boolean),
        () =>
            actionService.fetchActionsForSite({
                externalIds,
                forUser,
                moduleArea,
                fromDate,
                toDate,
                pageNum,
                pageSize,
                showCompleted,
                auditType,
                rating,
                sortOrder,
                orderByColumn,
                assignees,
                filteredText: filteredText && filteredText.length > 3 ? filteredText : undefined,
                nonUser
            }),
        {
            enabled: !!externalIds?.length,
        }
    );

export default useSiteCurrentActions;
