import { useState, useMemo } from "react";
import {
    makeStyles,
    Box,
    Container,
    Grid,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from "@material-ui/core";
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";
import PageBase from "../components/PageBase";
import ArticleTile from "./ArticleTile";
import TabPanel from "../components/TabPanel";
import { useParams } from "react-router-dom";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import { format } from "date-fns";
import caseNestDocumentService from "../services/caseNestDocumentService";
import { iconFromContentType } from "./utils";

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableRow-head': {
            backgroundColor: '#f9f9fa',
        },
        '& td':
        {
            cursor: 'pointer',
        }

    }
}));

const Category = () => {
    const { primaryCategoryName, subCategoryName } = useParams();
    const { data, isLoading } = useKnowledgeBank();

    const primaryCategory = useMemo(() => data.primaryCategories.find(c => c.name === primaryCategoryName.replaceAll("_", " ")), [data.primaryCategories, primaryCategoryName]);
    const subCategory = useMemo(() => data.subCategories.find(c => c.name === subCategoryName.replaceAll("_", " ")), [data.subCategories, subCategoryName]);

    console.log({ isLoading, data });    

    const classes = useStyles();
    const { Track, trackEvent } = useTracking({ page: `KnowledgeBank-Category:${primaryCategoryName}:${subCategoryName}` });  

    const articles = useMemo(() => {

        if (!subCategory || !primaryCategory)
            return [];

        let list = data.articles.filter(a => a.categoryId === subCategory.id && a.primaryCategory === primaryCategory.id);

        console.log({ subCategory, list });

        return list;

    }, [subCategory, primaryCategory, data.articles]);

    const documents = useMemo(() => {

        if (!subCategory || !primaryCategory)
            return [];

        let list = data.documents.filter(d => d.categoryId === subCategory.id && d.primaryCategory === primaryCategory.id);        

        return list;

    }, [subCategory, primaryCategory, data.documents]);

    console.log({ documents });

    const [tab, setTab] = useState("articles");    

    const downloadDocument = async (doc) => {

        const response = await caseNestDocumentService.fetchDocument(doc.identifier, 'arraybuffer')
        let blob = new Blob([response], { type: doc.contentType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = doc.filename;
        link.click();

        trackEvent({ event: `${trackingConstants.events.downloadKnowledgeDocument}: ${doc.id}` });
    }

    return (
        <PageBase>
            <Track>
                <Container>
                    <Box display="flex" flexDirection="column" gridGap="15px">
                        <h1>{subCategory.name}</h1>
                        <Tabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, v) => setTab(v)}
                        >
                            <Tab label="Articles" value="articles" />
                            <Tab label="Documents" value="documents" />
                        </Tabs>
                        <TabPanel value={tab} index="articles">
                            {articles.length === 0 && <h1>No articles</h1>}
                            <Grid container spacing={2}>
                                {articles.map(a =>
                                    <Grid item xs={12} md={4} key={a.id}>
                                        <ArticleTile article={a} />
                                    </Grid>
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tab} index="documents">
                            {documents.length === 0 && <h1>No documents</h1>}
                            {documents.length > 0 &&
                                <TableContainer component={Paper}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Created on</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {documents.map(d =>
                                                <TableRow key={d.id} onClick={() => downloadDocument(d)}>
                                                    <TableCell>
                                                        <Box display="flex" alignItems="center" gridGap="10px">
                                                            <Box>{iconFromContentType(d.contentType)}</Box>
                                                            <Box>{d.name}</Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {format(new Date(d.createdOn), "dd MMM yyyy")}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </TabPanel>
                    </Box>
                </Container>
            </Track>          
        </PageBase>
    );
}

export default Category;
