import React, { useState } from "react";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Link,
    makeStyles,
    Toolbar,
    Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useAuth } from "../contexts/authContext";
import layoutConstants from "../constants/layoutConstants";
import Footer from "../components/Footer";
import clsx from "clsx";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Help } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { red } from "@material-ui/core/colors";
import { ReactComponent as WorknestLogo } from "../images/myWorkNest_Purple_Negative_RGB.svg";
import { ReactComponent as HRSolutionsLogo } from "../images/HR-Solutions-Logo-2023.svg";
import NavigationDrawer from "../components/navigation/NavigationDrawer";
import appIds from "../constants/appIdConstants";
import UserMenu from "../components/navigation/UserMenu";
import { setIsTutorialOpen } from "../redux/actions/tutorialActions";
import useIsMobile from "../hooks/useIsMobile";
import QuickNavigationMenu from "../components/navigation/QuickNavigationMenu";
import KnowledgeBankBar from "../knowledgebank/KnowledgeBankBar";
import { isHRS } from "../constants/siteConfig";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: layoutConstants.drawerWidth,
            width: `calc(100% - ${layoutConstants.drawerWidth}px)`,
        },
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        backgroundColor: theme.palette.primary.main,
    },
    main: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    drawerMargin: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: -layoutConstants.drawerWidth,
        },
    },
    mainShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(layoutConstants.contentPaddingMultiplier),
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    },
    toolbar: theme.mixins.toolbar,
    userName: {
        fontWeight: 500,
    },
    appTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    siteSelect: {
        color: "inherit",
        "& .MuiAutocomplete-inputRoot, .MuiFormLabel-root, .MuiAutocomplete-clearIndicator, .MuiAutocomplete-popupIndicator":
        {
            color: "inherit",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "inherit",
            opacity: 0.5,
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            opacity: 1,
            borderColor: "inherit",
        },
    },
    appTitleLink: {
        display: "flex",
        alignItems: "center",
        "&:hover": {
            textDecoration: "none",
        },
    },
    userMenuContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(2),
    },
    contactSupportContainer: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2, 0),
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            marginRight: theme.spacing(2),
            padding: 0,
        },
    },
    contactSupportText: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            marginRight: theme.spacing(1),
        },
    },
    contactSupportNumber: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightLight,
        color: "#fff",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    impersonatingBanner: {
        backgroundColor: red[500],
        color: "white",
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        right: 0,
        height: layoutConstants.impersonationBannerHeight,
        display: "flex",
        justifyContent: "space-between",
        padding: `0 ${theme.spacing(2)}px`,
    },
    impersonatingText: {
        textAlign: "center",
    },
    impersonatingEndSession: {
        color: "white",
    },
    termsShift: {
        marginTop: layoutConstants.termsBannerHeight,
        background: "white",
    },
    impersonationShift: {
        marginTop: layoutConstants.impersonationBannerHeight,
    },
    logo: {
        width: "100%",
        maxWidth: "170px",
        maxHeight: "64px"
    },
    secondaryToolbar: {
        background: 'white',
        color: 'black'
    }
}));

const contactPhoneNumber = "0345 226 8393";

const AuthLayout = ({ children }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { activeAppId } = useSelector((state) => state.account);
    const { tutorial } = useSelector((state) => state.tutorial);
    const { impersonatingUser, endImpersonationSession } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();    

    const showSideMenu =
        activeAppId === appIds.safetynest ||
        activeAppId === appIds.casenest ||
        isMobile;

    const handleDrawerToggle = () => {
        setDrawerOpen((current) => !current);
    };

    const handleEndImpersonationSession = async () => {
        try {
            await endImpersonationSession();
        } catch (e) {
            console.error(e);
        }
    };

    const handleHelpClick = () => {
        dispatch(setIsTutorialOpen(true));
    };

    return (
        <>
            {impersonatingUser && (
                <Box className={classes.impersonatingBanner}>
                    <Typography className={classes.impersonatingText} variant="body2">
                        Impersonating{" "}
                        {`${impersonatingUser.name} (${impersonatingUser.email})`}
                    </Typography>
                    <Button
                        onClick={handleEndImpersonationSession}
                        variant="text"
                        className={classes.impersonatingEndSession}
                    >
                        End Session
                    </Button>
                </Box>
            )}
            <div className={classes.root}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: showSideMenu && !isMobile && drawerOpen,
                        [classes.impersonationShift]: !!impersonatingUser,
                    })}
                >
                    <Toolbar>
                        {showSideMenu && (
                            <IconButton
                                edge="start"
                                className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                                color="inherit"
                                aria-label="menu"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <div className={classes.appTitle}>
                            <Link
                                component={RouterLink}
                                to={"/"}
                                color={"inherit"}
                                className={classes.appTitleLink}
                            >
                                {isHRS ? <HRSolutionsLogo className={classes.logo} /> : <WorknestLogo className={classes.logo} />}
                            </Link>
                        </div>
                        <div className={classes.userMenuContainer}>
                            {!isMobile && (
                                <div className={classes.contactSupportContainer}>
                                    <div className={classes.contactSupportText}>
                                        Need Help? Call:
                                    </div>
                                    <a
                                        href={`tel:${contactPhoneNumber}`}
                                        className={classes.contactSupportNumber}
                                    >
                                        {contactPhoneNumber}
                                    </a>
                                </div>
                            )}
                            {!!tutorial && (
                                <IconButton color="inherit" onClick={handleHelpClick}>
                                    <Help />
                                </IconButton>
                            )}
                            <QuickNavigationMenu />
                            <UserMenu />
                        </div>
                    </Toolbar>
                    {location.pathname.startsWith("/knowledge") && <Toolbar className={classes.secondaryToolbar}><KnowledgeBankBar /></Toolbar>}
                </AppBar>                
                {showSideMenu && (
                    <NavigationDrawer
                        isOpen={drawerOpen}
                        onToggleOpen={handleDrawerToggle}
                    />
                )}
                <main
                    className={clsx(classes.main, {
                        [classes.drawerMargin]: showSideMenu && !isMobile,
                        [classes.mainShift]: drawerOpen,
                        [classes.impersonationShift]: !!impersonatingUser,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    {location.pathname.startsWith("/knowledge") && <div className={classes.drawerHeader} />}
                    <div className={classes.content}>
                        {children}
                    </div>
                    <Footer />
                </main>
            </div>
        </>
    );
};

export default AuthLayout;
