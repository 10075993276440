import {
    makeStyles,
    Box,
    Container,
    Typography,
    CircularProgress,
    Grid,
    IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import Epic from "./Epic";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";

const useStyles = makeStyles((theme) => ({
    panel: {
        border: '1px solid #bfbfbf',
        borderRadius: '5px',
        padding: '10px',
        height: '100%'
    },
    title: {
        background: theme.palette.title.background,
        color: theme.palette.title.text,
        padding: '2px 8px',
        borderRadius: '5px',
        width: 'inherit'
    },
    tileContent: {
        textOverflow: 'ellipsis',
        xheight: '30px',
        xmaxHeight: '25px',
        overflow: 'hidden',
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
    },

}));

const Categories = () => {
    const classes = useStyles();
    const { Track } = useTracking({
        page: 'KnowledgeBank-Categories'
    });

    const { data, isLoading } = useKnowledgeBank();   

    const primaryCategories = data?.primaryCategories;

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    return (
        <PageBase>
            <Track>
                <Container>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" gridGap="15px">
                        <Epic
                            title="Categories"
                            main="Lorem ipsum ..."
                        />
                        <Grid container spacing={2}>
                            {primaryCategories.map(c =>
                                <Grid item xs={12} md={4} key={c.id}>
                                    <Box className={classes.panel} display="flex" gridGap="5px" flexDirection="column">
                                        <Box display="flex" alignItems="center" style={{marginTop: "-8px"}}>
                                            <Box flexGrow={1}><Typography >{c.name}</Typography></Box>
                                            <IconButton component={Link} to={`/knowledge/category/${c.name.replaceAll(" ", "_") }`}><ArrowForwardIcon /></IconButton>
                                        </Box>
                                        <Box><Typography>Lorem ipsum</Typography></Box>                                       
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Track>
        </PageBase>
    );
}

export default Categories;
