import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
    makeStyles,
    Typography,
    Box,
} from "@material-ui/core";
import { format } from "date-fns";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";

const useStyles = makeStyles((theme) => ({
    panel: {
        border: '1px solid #bfbfbf',
        borderRadius: '5px',
        padding: '10px',
        height: '100%',
        cursor: 'pointer'
    },
    title: {
        background: theme.palette.title.background,
        color: theme.palette.title.text,
        padding: '2px 8px',
        borderRadius: '5px',
        width: 'inherit'
    },
    tileContent: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        fontSize: '0.9em'
    }
}));

const ArticleTile = ({ article }) => {
    const classes = useStyles();    
    const history = useHistory();

    const { data } = useKnowledgeBank();

    const categoryName = useCallback(id => data.subCategories.find(c => c.id === id).name, [data?.subCategories]);

    return (
        <Box className={classes.panel} display="flex" flexDirection="column" gridGap="5px" onClick={() => history.push(`/knowledge/article/${article.id}`) }>
            <Typography><span className={classes.title}>{categoryName(article.categoryId)}</span></Typography>
            <Typography>{article.name}</Typography>
            <Box flexGrow={1}><Typography className={classes.tileContent}>{article.summary}</Typography></Box>
            <Typography>{format(new Date(article.createdOn), "d MMM yyyy")}</Typography>
        </Box>       
    );
}

export default ArticleTile;
