import auth0 from 'auth0-js';

export const scopes = 'openid profile email'
export const socialConnectionNames = {
    microsoft: 'WorkNestAD',
    google: 'google-oauth2',
}

const webAuth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    responseType: 'token id_token',
    redirectUri : window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
});

export default webAuth;