import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden'
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.default
    },
    mainContent: {
        flex: 1,
        overflowY: 'auto',
        position: 'relative',
        height: 0,
        padding: theme.spacing(0, 0, 5)
    },
    contentContainer: {
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '540px',
            margin: '0 auto',
        }
    },
    footer: {
        position: 'sticky',
        bottom: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.default
    },
    progressContainer: {
        padding: theme.spacing(2, 0),
        backgroundColor: '#fff',
        borderBottom: '1px solid #E0E0E0'
    }
}));

function Layout({ 
    children, 
    onNext, 
    onBack, 
    showBackButton,
    nextButtonText,
    hideFooter = false
}) {
    const classes = useStyles();
        
    return (
        <div className={classes.root}>
            <Header />
            <main className={classes.mainContent}>
                <div className={classes.contentContainer}>
                    {children}
                </div>
            </main>
            {!hideFooter && (
                <Footer 
                    onNext={onNext}
                    onBack={onBack}
                    showBackButton={showBackButton}
                    nextButtonText={nextButtonText}
                />
            )}
        </div>
    );
}

export default Layout; 