import { makeStyles, Paper } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { moduleIconForEnum } from "../../constants/moduleAreaConstants";
import { useActionDetails } from "../../contexts/ActionContext";
import ActionAttachments from "./ActionAttachments";
import ActionComments from "./ActionComments";
import ActionDetails from "./ActionDetails";
import ActionTabs from "./ActionTabs";
import ActionRatingChip from "../ActionRatingChip";
import LoggedByExternalUser from "../../components/NonUser/LoggedByExternalUser";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        rowGap: theme.spacing(1.5),
        width: '50vw',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    pill: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        columnGap: theme.spacing(0.25),
        backgroundColor: '#f6f5f8',
        color: '#12054d',
        fontWeight: 500,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: '0.75rem',
    },
    icon: {
        fontSize: '1.25rem',
    },
    divIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fontSize: '1.25rem',
        }
    },
    pillComtainer: {
        display: 'flex',
        columnGap: theme.spacing(1),
    },
    backdrop: {
        backgroundColor: '#fafafb',
        padding: theme.spacing(3, 2),
        margin: theme.spacing(-1.5, -2),
        borderTop: '1px solid #c4c1d3',
    },
    actionTitle: {
        fontSize: '1.1rem',
    },
    marginBottom: {
        marginBottom: theme.spacing(3),
        width: '50vw',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        maxWidth: '100%',
        boxSizing: 'border-box'
    },
}));

const ON_THE_SPOT_DESCRIPT = " (On-the-Spot) ";

export default function ActionContainer() {
    const classes = useStyles();
    const { sites } = useSelector(
        (state) => state.account
    );

    const { action, selectedTab, actionTabEnum } = useActionDetails()

    const actionSite = () => (
        <div className={classes.pill}>
            <LocationOn className={classes.icon} />
            {sites[action.data?.siteExternalId]?.name ?? "Unknown"}
        </div>
    )

    const actionModule = () => (
        <div className={classes.pill}>
            <div className={classes.divIcon}>
                {moduleIconForEnum[action.data?.moduleType] ?? "N/A"}
            </div>
            {action.data?.moduleName ?? "Unknown"} {action.data?.isAdHoc ? ON_THE_SPOT_DESCRIPT : ""}
        </div>
    )
    
    const actionRating = () => (
        action.data && (
            <div className={classes.pill}>
                <ActionRatingChip rating={action.data?.rating} />
            </div>
        )
    )

    return (
        <div className={classes.wrapper}>
            {action.data?.nonUser &&
            <LoggedByExternalUser nonUser={action.data?.nonUser} className={classes.marginBottom} />
}
            <Paper className={classes.container}>    
                <div className={classes.pillComtainer}>
                {actionSite()}
                {actionModule()}
                {actionRating()}
            </div>
            <div className={classes.actionTitle}>
                {action.data?.description ?? 'Action'}
            </div>
            <div>
                <ActionDetails />
            </div>
            <div>
                <ActionTabs />
            </div>
            <div className={classes.backdrop}>
                {selectedTab === actionTabEnum.COMMENTS && <ActionComments />}
                {selectedTab === actionTabEnum.ATTACHMENTS && <ActionAttachments />}
            </div>
            </Paper>
        </div>
    )
}