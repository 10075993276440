import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import NonUserForm from '../common/NonUserForm';
import ActionDetails from './ActionDetails';
import { useSnackbar } from "notistack";
import useAddAnonymousAction from "../../../hooks/mutations/useAddAnonymousAction";
import NonUserSubmittedConfirmation from '../common/NonUserSubmittedConfirmation';
import useAddNonUser from '../../../hooks/mutations/useAddNonUser';
import { INTERNAL_REDIRECT_URL_KEY } from "../../../../constants/authConfig";

function NonUserActionForm() {
    const location = useLocation();
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [submittedActionId, setSubmittedActionId] = useState(null);
    const TOTAL_STEPS = 1;
    const { enqueueSnackbar } = useSnackbar();
    const addAnonymousAction = useAddAnonymousAction();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const externalId = queryParams.get('externalId');
        
        if (!externalId) {
            enqueueSnackbar("Incorrect link provided, please contact your administrator", { variant: "error" });
            history.push('/');
        }
    }, [location, history, enqueueSnackbar]);

    const handleUserDetailsSubmit = (userData) => {
        setFormData(userData)
        setStep(1);
    };   

    const handleFormSubmit = async () => {
        try {
            addAnonymousAction.mutate(formData ,{
                onSuccess: (response) => {                          
                    enqueueSnackbar("Issue submitted successfully", { variant: "success" });
                    setSubmittedActionId(response.id);
                    localStorage.setItem(INTERNAL_REDIRECT_URL_KEY, '/');
                    setStep(2);
                },
                onError: (error) => {
                    console.error('Error submitting issue:', error);
                    const errorMessage = error.response?.data?.message || "Could not save issue.";
                    enqueueSnackbar(errorMessage, { variant: "error" });
                },
            });

        } catch (error) {
            console.error('Error in form submission:', error);
            enqueueSnackbar("An error occurred while submitting the form", { variant: "error" });
        }
    };

    const redirectToActionAdd = () => {
        localStorage.setItem(INTERNAL_REDIRECT_URL_KEY, '/safetynest?createActionDialogOpen=true');
        history.push('/');
    };

    const handleBack = () => {
        setStep(prev => Math.max(0, prev - 1));
    };

    switch (step) {
        case 0:
            return (
                <NonUserForm
                    title="Report an Issue"
                    subtitle="Help us to keep everyone safe. Use this form to report hazards, maintenance issues, or share improvement ideas."
                    formFields={['name', 'email', 'phone']}
                    sectionTitle="Your Details"
                    sectionHelperText="Please provide your contact information so we can follow up if needed."
                    onSubmit={handleUserDetailsSubmit}
                    onBack={step > 0 ? handleBack : undefined}
                    formData={formData}
                    setFormData={setFormData}
                    currentStep={step}
                    totalSteps={TOTAL_STEPS}
                    redirect={redirectToActionAdd}
                />
            );
        case 1:
            return (
                <ActionDetails
                    onSubmit={handleFormSubmit}
                    onBack={handleBack}
                    formData={formData}
                    setFormData={setFormData}   
                    currentStep={step}
                    totalSteps={TOTAL_STEPS}
                />
            );      
        case 2:
            return (
                <NonUserSubmittedConfirmation 
                    id={submittedActionId} type="Issue" idPrefix="ISS"
                />
            );
        default:
            return null;
    }
}

export default NonUserActionForm;