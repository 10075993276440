import React from 'react';
import { Box, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    pageTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif'
    },
    pageHelperText: {
        color: '#48464a',
        height: '72px',
        alignSelf: 'stretch',
        flexGrow: 0,
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
    }
}));

function PageHeader({ title, subtitle }) {
    const classes = useStyles();
    
    return (
        <>
            <Box textAlign="center" mb={4} pt={3} px={3}>
                <Typography 
                    variant="h2" 
                    className={classes.pageTitle}
                    gutterBottom
                >
                    {title}
                </Typography>
                <Typography variant="body2" className={classes.pageHelperText}>
                    {subtitle}
                </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>
        </>
    );
}

export default PageHeader;