import React from "react";
import ActionRatingChip from "../ActionRatingChip";
import {   
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TablePagination,   
    Typography,
    TableHead,
    alpha,
} from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    backgroundColor: "#eef0ff",
    border: "1px solid #9caadb",
    width: 'inherit',
    boxSizing: 'border-box',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infoIcon: {
    color: "#374570",
    alignSelf: "center",
  },
  errorIcon: {
    color: theme.palette.error.main,
    alignSelf: "center",
  },
  content: {
    flex: 1,
  },
  title: {
    color: "#374570",
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
  },
  details: {
    display: "flex",
    gap: theme.spacing(1),
    color: "#374570",
    "& span:not(:last-child)::after": {
      content: '"|"',
      marginLeft: theme.spacing(1),
      color: "#374570",
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: "#374570",
  },
  paginationArea: {
    height: '52px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  tableContainer: {   
      overflowY: 'hidden' 
  },
  tableWrapper: {
    flex: 1,
    overflow: 'hidden'
    },
    moduleCellContainer: {
        display: "flex",
        alignItems: "center",
    },
    moduleCellText: {
        marginLeft: theme.spacing(1),
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.08),
        },
    },
    descriptionCell: {
        maxWidth: '300px',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
}));

const NonUserActionsTable = ({ 
    actions = [], 
    handleRowClick, 
    isModule = false, 
    module = null, 
    ModuleFormat, 
    dueDateFormat, 
    page = 0,
    rowsPerPage = 5,
    count = 0, 
    handleChangePage,
    handleChangeRowsPerPage
}) => {
    const { sites } = useSelector((state) => state.account);
    const classes = useStyles();

  return (
      <TableContainer className={ classes.tableContainer }>
          <Table size="small">   
              <TableHead>
                  <TableRow>
                      <TableCell>
                          Description
                      </TableCell>
                      <TableCell>
                          Priority
                      </TableCell>
                      <TableCell>
                          Site
                      </TableCell>
                      <TableCell>
                          Module
                      </TableCell>
                      <TableCell>
                          Responsible
                      </TableCell>
                      <TableCell>
                          Due
                      </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {actions?.length > 0 && actions.map((row) => (
                              <TableRow
                                  key={`${row.id}-${row.isDri}`}
                                  onClick={() => handleRowClick(row)}
                                  className={ classes.tableRow }
                              >
                          <TableCell className={classes.descriptionCell}>
                                      {row.isAdHoc ?
                                          <div className={classes.moduleCellContainer}>                                            
                                              <Typography variant="body2" className={classes.moduleCellText}>
                                                  {row.description}
                                              </Typography>
                                          </div> : row.description}
                                  </TableCell>
                                  <TableCell>
                                      <ActionRatingChip rating={row?.rating} />
                                  </TableCell>
                                  <TableCell>
                                      {sites[row.siteExternalId]?.name ?? "Unknown"}
                                  </TableCell>

                                  {!isModule && (
                                      <TableCell>
                                          <ModuleFormat name={row.moduleName} type={row.auditType} />
                                      </TableCell>
                                  )}
                                  <TableCell>
                                      Not Set
                          </TableCell>
                          <TableCell>{dueDateFormat(row.statusName, row.dueDate)}</TableCell>
                              </TableRow>
                          ))
                  }
              </TableBody>
          </Table>
          <div className={classes.paginationArea}>
              <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={(event, newPage) => {                 
                      handleChangePage(event, newPage);
                  }}
                  rowsPerPage={5}
                  rowsPerPageOptions={[5]}
                  disabled={count <= 5}
              />
          </div>
      </TableContainer>
  );
};

export default NonUserActionsTable; 