import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import NonUserForm from '../common/NonUserForm';
import IncidentDetails from './IncidentDetails';
import PeopleDetails from './PeopleDetails';
import IncidentFormStep from './TemplateIncidentForm';
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { AnonymousIncidentBuilder } from "../../../utils/AnonymousIncidentBuilder";
import useAddAnonymousIncident from "../../../hooks/mutations/useAddAnonymousIncident";
import NonUserSubmittedConfirmation from '../common/NonUserSubmittedConfirmation';
import { INTERNAL_REDIRECT_URL_KEY } from "../../../../constants/authConfig";

function NonUserIncidentForm() {
    const location = useLocation();
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [submittedIncidentId, setSubmittedIncidentId] = useState(null);
    const TOTAL_STEPS = 4;
    
    const { questionResponses, uploads } = useSelector((state) => state.formCompleter);
    const { enqueueSnackbar } = useSnackbar();
    const addAnonymousIncident = useAddAnonymousIncident();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const externalId = queryParams.get('externalId');
        
        if (!externalId) {
            enqueueSnackbar("Incorrect link provided, please contact your administrator", { variant: "error" });
            history.push('/');
        }
    }, [location, history, enqueueSnackbar]);

    const handleUserDetailsSubmit = (userData) => {
        setFormData(prev => ({
            ...prev,
            ...userData
        }));
        setStep(1);
    };

    const handleIncidentDetailsSubmit = (incidentData) => {
        setFormData(prev => ({
            ...prev,
            ...incidentData
        }));
        setStep(2);
    };

    const handlePeopleDetailsSubmit = (peopleData) => {
        setFormData(prev => ({
            ...prev,
            ...peopleData
        }));
        setStep(3);
    };

    const redirectToIncidentAdd = () => {
        localStorage.setItem(INTERNAL_REDIRECT_URL_KEY, '/safetynest/incidents/add');
        history.push('/');
    };

    const handleFormSubmit = async () => {
        try {
            const completedForm = new AnonymousIncidentBuilder(
                {
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone
                },
                {
                    description: formData.description,
                    incidentDate: formData.incidentDate,
                    incidentType: formData.incidentType,
                    incidentCategoryId: formData.incidentCategoryId,
                    templateVersionId: formData.templateVersionId,
                    siteExternalId: formData.siteExternalId,
                    canCompleteIncidentInvestigation: formData.canCompleteIncidentInvestigation,
                    people: formData.people
                },
                uploads,
                questionResponses
            );

            const result = completedForm
                .buildIncident()
                .buildResponses()
                .build();            

            addAnonymousIncident.mutate(result, {
                onSuccess: (response) => {
                    enqueueSnackbar("Incident submitted successfully", { variant: "success" });
                    setSubmittedIncidentId(response.id);
                    localStorage.setItem(INTERNAL_REDIRECT_URL_KEY, '/');
                    setStep(4);
                },
                onError: (error) => {
                    console.error('Error submitting incident:', error);
                    const errorMessage = error.response?.data?.message || "Could not save incident.";
                    enqueueSnackbar(errorMessage, { variant: "error" });
               },
            });

        } catch (error) {
            console.error('Error in form submission:', error);
            enqueueSnackbar("An error occurred while submitting the form", { variant: "error" });
        }
    };

    const handleBack = () => {
        setStep(prev => Math.max(0, prev - 1));
    };

    switch (step) {
        case 0:
            return (
                <NonUserForm
                    title="Report an Incident"
                    subtitle="Use this form to report incidents that have occurred and help us maintain a safe environment."
                    formFields={['name', 'email', 'phone']}
                    sectionTitle="Your Details"
                    sectionHelperText="Please provide your contact information so we can follow up if needed."
                    onSubmit={handleUserDetailsSubmit}
                    onBack={step > 0 ? handleBack : undefined}
                    formData={formData}
                    setFormData={setFormData}
                    currentStep={1}
                    totalSteps={TOTAL_STEPS}
                    redirect={redirectToIncidentAdd}
                />
            );
        case 1:
            return (
                <IncidentDetails
                    onSubmit={handleIncidentDetailsSubmit}
                    onBack={handleBack}
                    formData={formData}
                    setFormData={setFormData}
                    currentStep={2}
                    totalSteps={TOTAL_STEPS}
                />
            );
        case 2:
            return (
                <PeopleDetails
                    onSubmit={handlePeopleDetailsSubmit}
                    onBack={handleBack}
                    formData={formData}
                    setFormData={setFormData}
                    currentStep={3}
                    totalSteps={TOTAL_STEPS}
                />
            );
        case 3:
            return (
                <IncidentFormStep
                    onSubmit={handleFormSubmit}
                    onBack={handleBack}
                    formData={formData}
                    setFormData={setFormData}
                    currentStep={4}
                    totalSteps={TOTAL_STEPS}
                />
            );
        case 4:
            return (
                <NonUserSubmittedConfirmation 
                    id={submittedIncidentId} type="Incident" idPrefix="INC"
                />
            );
        default:
            return null;
    }
}

export default NonUserIncidentForm; 