import React, { useState, useEffect } from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Link,
    makeStyles, Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {riddorReportableStatus, useIncidentDetails} from "../../contexts/IncidentContext";
import { incidentTypeList } from "../../constants/incidentType";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import {grey} from "@material-ui/core/colors";
import useIncidentCategories from "../../hooks/queries/useIncidentCategories";
import { useSelector } from "react-redux";
import useAllEnabledModuleTemplates from "../../hooks/queries/useAllEnabledModuleTemplates";
import moduleAreas from "../../constants/moduleAreaConstants";
import { useLocation } from "react-router-dom";
import useAccountAppSites from "../../../hooks/queries/useAccountAppSites";

const useStyles = makeStyles(() => ({
  datePicker: {
    margin: 0,
  },
}));

const maximumDate = new Date().setHours(23, 59, 59);

function IncidentDetailsForm() {
  const classes = useStyles();

  // Context where values and functions are stored for use throughout the new/update incident process
  const {
    incidentType,
    setIncidentType,
    incidentDate,
    setIncidentDate,
    incidentDescription,
    setIncidentDescription,
    riddorReportable,
    setRiddorReportable,
    reportingDate,
    setReportingDate,
    referenceNumber,
    setReferenceNumber,
    incidentCategoryId,
    setIncidentCategoryId, 
    setTemplateVersionId, 
      templateId,
  } = useIncidentDetails();

    const location = useLocation();
    const updating = location.pathname.includes("edit");
    const { data: incidentCategories } = useIncidentCategories();
    const [forms, setForms] = useState([]);  
    const [formSelection, setFormSelection] = useState("");  
    const { selectedSite, activeAppId, appSiteIds, selectedAccount, sites } = useSelector((state) => state.account);
    let siteExternalIds = selectedSite ? [selectedSite.externalId] : appSiteIds[activeAppId];

    const { data: accountSitesData } = useAccountAppSites({
        childExternalIds: [
            ...(selectedAccount?.childExternalIds || []),
            selectedAccount?.account.externalId,
        ],
        activeAppId,
    });

    let backupSites;

    if (accountSitesData !== undefined) {
         backupSites = accountSitesData.map(item => item.externalId);
    }

    // Get all Templates
    const { data: templatesData, refetch } = useAllEnabledModuleTemplates({
        moduleArea: moduleAreas.INCIDENTS,
        accountId: selectedAccount.account.externalId,
        siteExternalIds: siteExternalIds ?? backupSites,
        includeDisabled: updating ? "true" : "false",          
    });

    // Filter form templates by the incident type, with worknest default forms first
    async function populateFormDropdown(incidentType) {   
        if (templatesData?.length > 0) {        
            const filteredTemplates = templatesData.filter(f => f.incidentType === incidentType);            
            const sortedResults = filteredTemplates.sort((a, b) => {              
                if (!a.siteExternalId && b.siteExternalId) return -1; 
                if (a.siteExternalId && !b.siteExternalId) return 1;  

                return a.name.localeCompare(b.name);
            });

            // Map to values and labels for the dropdown
            let reducedArray = sortedResults.map(item => ({
                value: item.templateVersionId,
                label: item.name,
            }));        

            // If in edit mode, ensure the current template is included
            if (updating && templateId != null) {
                const correctTemplate = templatesData?.find(t => t.id === templateId);
                if (correctTemplate && !reducedArray.some(item => item.value === correctTemplate.templateVersionId)) {
                    reducedArray.push({ 
                        value: correctTemplate.templateVersionId, 
                        label: correctTemplate.name 
                    });
                }
            }

            // Set the form dropdown, and the context with the current default selected value.
            if (reducedArray.length > 0) {
                setForms(reducedArray);  
                
                if (!updating) {
                    const latestTemplate = filteredTemplates.reduce((max, current) => {
                        return current.templateVersionId > (max.templateVersionId || 0) ? current : max;
                    }, {});
                    setTemplateVersionId(latestTemplate.templateVersionId ?? reducedArray[0].value);
                    setFormSelection(latestTemplate.templateVersionId ?? reducedArray[0].value);
                } else if (templateId != null) {
                    const correctTemplate = templatesData?.find(t => t.id === templateId);
                    if (correctTemplate) {
                      setFormSelection(correctTemplate.templateVersionId);
                    }
                }
            }  
        }        
    }
 
    function customOrWorknestTemplate(templateVersionId) {
        if (templatesData?.length > 0) {
            let template = templatesData.find(t => t.templateVersionId === templateVersionId)
            let siteExternalId = template ? template.siteExternalId : undefined;
            return siteExternalId === undefined ? "Worknest" : "Custom";         
        }
    }

    // On page load
    useEffect(() => {    
        if (templatesData?.length > 0) {
            populateFormDropdown(incidentType);
        }
        else {
            refetch();
            if (templatesData?.length > 0) {
                populateFormDropdown(incidentType);
            }
        }
    }, [templatesData, incidentType, templateId]);

  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <KeyboardDatePicker
          required
          className={classes.datePicker}
          format="dd/MM/yyyy"
          fullWidth
          label="Incident date"
          inputVariant="outlined"
          value={incidentDate}
          onChange={setIncidentDate}
          maxDate={maximumDate}
          maxDateMessage={"Incident date must be in the past."}
        />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveSelect
            required
            fullWidth
            label="Incident type"
            options={incidentTypeList}
            optionValueKey="value"
            optionLabelKey="label"
            value={incidentType}
            onChange={(e) => { setIncidentType(e.target.value); populateFormDropdown(e.target.value); }}
            disabled={updating}
        />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveSelect
            required
            fullWidth
            label="Form"
            options={forms.map((form) => ({
                ...form,
                label: form.label,  
                chipCaption: customOrWorknestTemplate(form.value)
            }))}
            optionValueKey="value"
            optionLabelKey="label"
            value={formSelection || ""}
            onChange={(e) => setFormSelection(e.target.value)}
            disabled={updating}                   
        />            
      </Grid>
      <Grid item xs={12}>
        <ResponsiveSelect
            required
            fullWidth
            label="Incident category"
            options={incidentCategories ?? []}
            optionValueKey="id"
            optionLabelKey="description"
            value={incidentCategoryId || ""}
            onChange={(e) => setIncidentCategoryId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          variant="outlined"
          fullWidth
          label="Description"
          value={incidentDescription || ""}
          multiline={true}
          onChange={(e) => setIncidentDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">RIDDOR</Typography>
        <FormControl>
          <FormLabel id="riddor-reportable-radio-group">
            <Typography variant="subtitle2" style={{ color: grey[500] }}>
              Is the incident RIDDOR reportable? <Link color="secondary" href="https://www.hse.gov.uk/riddor/reportable-incidents.htm" target="_blank">What is RIDDOR?</Link>
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="riddor-reportable-radio-group"
            name="riddor-reportable-radio-group"
            value={riddorReportable}
            onChange={e => setRiddorReportable(parseInt(e.target.value))}
          >
            <FormControlLabel control={<Radio />} value={riddorReportableStatus.DONT_KNOW.value} label="Do not know at this time" />
            <FormControlLabel control={<Radio />} value={riddorReportableStatus.NO.value} label="No" />
            <FormControlLabel control={<Radio />} value={riddorReportableStatus.YES.value} label="Yes" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {riddorReportable === riddorReportableStatus.YES.value ?
        <>
            <Grid item xs={12}>
                <KeyboardDatePicker
                    onChange={date => setReportingDate(date)}
                    value={reportingDate}
                    fullWidth
                    inputVariant="outlined"
                    label="RIDDOR Reporting Date"
                    format="dd/MM/yyyy"
                    helperText="Date the incident was reported to HSE"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={referenceNumber}
                    onChange={e => setReferenceNumber(e.target.value)}
                    label="RIDDOR Reference Number"
                    helperText="10-digit reference number provided by HSE when incident was reported"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
        </> :
        null
      }      
    </Grid>
  );
}

export default IncidentDetailsForm;
