import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Chip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  dialog: {
    touchAction: 'none',
    '& .MuiDialog-paper': {
      margin: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        maxHeight: '100%',
        height: '100%',
        borderRadius: 0,
        width: '100vw',
        maxWidth: '100vw',
        overflowX: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    '& .MuiDialog-container': {
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        alignItems: 'flex-start',
        width: '100vw',
        maxWidth: '100vw',
        position: 'fixed',
        left: 0,
        right: 0,
        margin: 'auto'
      }
    }
  },
  dialogTitle: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #E4E4E7',
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1,
      width: '100%',
      padding: theme.spacing(1, 2)
    }
  },
  closeButton: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-1)
    }
  },
  titleText: {
    fontSize: '22px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      textAlign: 'left'
    }
  },
  addButton: {
    color: '#6750A4',
    fontSize: '14px',
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-1)
    }
  },
  dialogContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      maxWidth: 'calc(100vw - 32px)',
      margin: '0 auto',
      '& form': {
        width: '100%'
      }
    }
  },
  field: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  dialogActions: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileActions: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(2)
    }
  },
  mobileActionButton: {
    minWidth: 48 
  }
}));

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

function PeopleDialog({ open, onClose, onSave, initialData, genderTypeList, injuryTypeList, incidentBodyPartsList }) {
  const classes = useStyles();
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState({});
  
  const [formData, setFormData] = useState(initialData || {
    name: '',
    gender: '',
    dateOfBirth: '',
    address: '',
    isEmployee: false,
    role: '',
    injuryType: '',
    injuredBodyParts: [],
    wasFirstAidProvided: false,
  });

  useEffect(() => {
    if (open) {
      setFormData(initialData || {
        name: '',
        gender: '',
        dateOfBirth: '',
        address: '',
        isEmployee: false,
        role: '',
        injuryType: '',
        injuredBodyParts: [],
        wasFirstAidProvided: false,
      });
    }
  }, [open, initialData]);

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name?.trim()) {
      errors.name = 'Name is required';
    }
    if (formData.gender === null || formData.gender === undefined || formData.gender === '') {
      errors.gender = 'Gender is required';
    }
    if (!formData.injuryType) {
      errors.injuryType = 'Injury type is required';
    }
    if (!formData.injuredBodyParts?.length ) {
      errors.injuredBodyParts = 'At least one injured body part is required';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      onSave(formData);
      setFormData({
        name: '',
        gender: '',
        dateOfBirth: '',
        address: '',
        isEmployee: false,
        role: '',
        injuryType: '',
        injuredBodyParts: [],
        wasFirstAidProvided: false,
      });
      setFormErrors({});
      onClose();
    } catch (error) {
      console.error('Error saving person:', error);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      className={classes.dialog}
      scroll="paper"
      fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
    >
      <div className={classes.dialogTitle}>
        <div className={classes.mobileActions}>
          <IconButton 
            className={`${classes.closeButton} ${classes.mobileActionButton}`}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.titleText}>
            {initialData ? 'Edit Person' : 'Add Person'}
          </Typography>
          <Button
            className={`${classes.addButton} ${classes.mobileActionButton}`}
            onClick={handleSubmit}
          >
            Add
          </Button>
        </div>
        <Typography className={classes.titleText} style={{ display: useMediaQuery(theme.breakpoints.down('sm')) ? 'none' : 'block' }}>
          {initialData ? 'Edit Person' : 'Add Person'}
        </Typography>
      </div>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit} noValidate>
          <Typography variant="subtitle1" style={{ marginBottom: '16px' }}>
            Personal Details
          </Typography>

          <TextField
            label="Name"
            fullWidth
            required
            variant="outlined"
            className={`${classes.field} ${classes.formControl}`}
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
              if (formErrors.name) {
                setFormErrors(prev => ({ ...prev, name: '' }));
              }
            }}
            error={!!formErrors.name}
            helperText={formErrors.name}
          />

          <Box display="flex" gap={2} className={classes.field}>
            <FormControl 
                variant="outlined" 
                className={classes.formControl}
                error={!!formErrors.gender}
                style={{ width: '60%', paddingRight: '15px' }}
            >
                <InputLabel>Gender*</InputLabel>
                <Select
                    value={formData.gender ?? ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        setFormData(prev => ({ ...prev, gender: value }));
                        if (formErrors.gender) {
                            setFormErrors(prev => ({ ...prev, gender: '' }));
                        }
                    }}
                    label="Gender*"
                    required
                >
                    {genderTypeList.map((gender) => (
                        <MenuItem key={gender.value} value={gender.value}>
                            {gender.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                label="Date of birth"
                type="date"
                variant="outlined"
                style={{ width: '70%' }}
                InputLabelProps={{ shrink: true }}
                value={formData.dateOfBirth}
                onChange={(e) => setFormData({ ...formData, dateOfBirth: e.target.value })}
                inputProps={{
                    max: new Date().toISOString().split('T')[0]
                }}
            />
          </Box>

          <TextField
            label="Address"
            fullWidth
            variant="outlined"
            className={classes.field}
            value={formData.address}
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isEmployee}
                onChange={(e) => setFormData({ ...formData, isEmployee: e.target.checked })}
                style={{ color: '#6750A4' }}
              />
            }
            label="Is this person an employee?"
          />

          {formData.isEmployee && (
            <TextField
              label="Role"
              fullWidth
              variant="outlined"
              className={classes.field}
              value={formData.role}
              onChange={(e) => setFormData({ ...formData, role: e.target.value })}
            />
          )}

          <Typography variant="subtitle1" style={{ margin: '24px 0 16px' }}>
            Injury Description
          </Typography>

          <FormControl fullWidth variant="outlined" className={classes.field} error={!!formErrors.injuryType}>
            <InputLabel>Injury type</InputLabel>
            <Select
              value={formData.injuryType}
              onChange={(e) => {
                setFormData({ ...formData, injuryType: e.target.value });
                if (formErrors.injuryType) {
                  setFormErrors(prev => ({ ...prev, injuryType: '' }));
                }
              }}
              label="Injury type"
              required
            >
              {injuryTypeList.map((injury) => (
                <MenuItem key={injury.value} value={injury.value}>
                  {injury.label}
                </MenuItem>
              ))}
            </Select>
            {formErrors.injuryType && <Typography color="error">{formErrors.injuryType}</Typography>}
          </FormControl>

          <Autocomplete
            multiple
            value={Array.isArray(formData.injuredBodyParts) 
                ? formData.injuredBodyParts
                    .map(value => incidentBodyPartsList.find(part => part.value === value))
                    .filter(Boolean)
                : []
            }
            onChange={(e, newValue) => {
                setFormData(prev => ({
                    ...prev,
                    injuredBodyParts: newValue.map(item => item.value)
                }));
            }}
            options={incidentBodyPartsList}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={uncheckedIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: '#6750A4' }}
                        checked={selected}
                    />
                    {option.label}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Injured body parts"
                    className={classes.field}
                    error={Boolean(formErrors.injuredBodyParts)}
                    helperText={formErrors.injuredBodyParts}
                    inputProps={{
                        ...params.inputProps,
                        required: false
                    }}
                />
            )}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.wasFirstAidProvided}
                onChange={(e) => setFormData({ ...formData, wasFirstAidProvided: e.target.checked })}
                style={{ color: '#6750A4' }}
              />
            }
            label="Was first aid provided?"
          />
        </form>
      </DialogContent>
      <div className={classes.dialogActions}>
        <Button
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          {initialData ? 'Save Changes' : 'Add'}
        </Button>
      </div>
    </Dialog>
  );
}

export default PeopleDialog; 