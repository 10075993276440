import { Button, Checkbox, CircularProgress, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import { formatShortMonthDate } from "../../../utils/dateTimeUtils";
import { useActionDetails } from "../../contexts/ActionContext";
import ActionStatusChip from "../ActionStatusChip";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
    },
    twoColumn: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'min-content auto',
        rowGap: theme.spacing(1.5),
        columnGap: theme.spacing(8),
        alignItems: 'center',
    },
    keyText: {
        color: '#716994',
    },
    userName: {
        textDecoration: 'underline',
        color: '#5c45c7',
        maxWidth: 'max-content',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    userSelect: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
    radioContainer: {
        fontSize: '0.875rem',
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
    },
}));

export default function ActionDetails() {
    const classes = useStyles();
    const [editAssignedTo, setEditAssignedTo] = useState(false);
    const [editDueDate, setEditDueDate] = useState(false);   

    const {
        action,
        assignedEmployeeExternalId,
        setAssignedEmployeeExternalId,
        isEditAllowed,
        isComplete,
        setIsComplete,
        dueDate,
        setDueDate
    } = useActionDetails()
    
    const { activeAppId, appSiteIds, selectedSite } = useSelector(
        (state) => state.account
    );

    const siteUsers = useContactsForAppAndSite({
        siteExternalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
    });

    const onAssignedToChange = (e) => {
        setAssignedEmployeeExternalId(e.target.value);
        setEditAssignedTo(false);        
    };

    const onDueDateChange = (date) => {
        if (date) {
            setDueDate(date);
        }        
        setEditDueDate(false);       
    };

    const UserName = ({ userId }) => {
        if (siteUsers.isLoading || action.isLoading)
            return <CircularProgress size={20} />;

        if (siteUsers.isError || action.isError)
            return <Typography>Unknown</Typography>;

        let fullName = 'Not Set'

        if (userId)
            fullName = `${siteUsers?.data[userId]?.firstName} ${siteUsers?.data[userId]?.lastName}`;

        return (
            <Typography>
                {fullName}
            </Typography>
        )
    };

    const keyText = (text) => <Typography className={classes.keyText} variant="subtitle2">{text}</Typography>

    return (
        <div className={classes.container}>
            <div className={classes.twoColumn}>
                {action.data?.recommendation && (
                    <>
                        {keyText('Recommendation')}
                        <Typography>
                            {action.data.recommendation}
                        </Typography>
                    </>
                )}
                {keyText('Status')}
                <ActionStatusChip
                    status={action.data?.status}
                    statusName={action.data?.statusName}
                />
                {keyText('Due')}
                {
                    isEditAllowed
                        ? <div className={classes.userSelect}>
                            <KeyboardDatePicker
                                format="dd/MM/yyyy"
                                variant='inline'
                                inputVariant="outlined"
                                label="Due Date"
                                value={dueDate}
                                onChange={onDueDateChange}                               
                            />
                         </div>
                        : <Typography                          
                        >
                            {dueDate && formatShortMonthDate(dueDate)}
                        </Typography>
                }
                {keyText('Responsible')}
                {isEditAllowed ? (
                    <div className={classes.userSelect}>
                        <ResponsiveSelect
                            options={Object.values(siteUsers?.data ?? {}).sort((a, b) => a.firstName.localeCompare(b.firstName))}
                            optionLabelFunction={(x) => `${x.firstName} ${x.lastName}`}
                            optionValueKey={"externalId"}
                            value={assignedEmployeeExternalId}
                            onChange={onAssignedToChange}
                            margin="dense"
                            style={{ margin: 0, minWidth: '110px' }}
                        />                      
                    </div>
                ) : (
                    <div>
                        <UserName userId={assignedEmployeeExternalId} />
                    </div>
                )}
                {keyText('Created By')}
                {action.data?.createdByEmployeeExternalId ? (
                    <UserName userId={action.data?.createdByEmployeeExternalId} />
                ) : (
                    "N/A"
                )}
                {action.data?.templateName && keyText('Form Origin')}
                {
                    action.data?.templateName &&
                    <Typography>
                        {action.data.templateName}
                    </Typography>
                }
                {action.data?.auditId && keyText('Audit Id')}
                {
                    action.data?.auditId &&
                    <Typography>
                        {action.data.auditId}
                    </Typography>
                }
                {action.data?.incidentId && keyText('Incident Id')}
                {
                    action.data?.incidentId &&
                    <Typography>
                        {action.data.incidentId}
                    </Typography>
                }
                {keyText('Stage')}
                <div>
                    <FormControlLabel
                        className={classes.radioContainer}
                        value={isComplete}
                        disabled={!isEditAllowed}
                        control={
                            <Checkbox
                                checked={isComplete}
                                onChange={() => setIsComplete(!isComplete)}
                            />
                        }
                        label={isComplete ? 'Complete' : 'Incomplete'}
                    />
                </div>
            </div>
        </div>
    )
}