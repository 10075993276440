import { useMemo, useCallback } from "react";
import {
    makeStyles,
    Box,
    Container,
    Divider,
    Typography,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";
import PageBase from "../components/PageBase";
import { useParams } from "react-router-dom";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import useKnowledgeBankArticle from "../hooks/queries/useKnowledgeBankArticle";
import caseNestDocumentService from "../services/caseNestDocumentService";
import ArticleTile from "./ArticleTile";
import { iconFromContentType } from "./utils";

const useStyles = makeStyles((theme) => ({
    title: {
        background: theme.palette.title.background,
        color: theme.palette.title.text,
        padding: '2px 8px',
        borderRadius: '5px',
        width: 'inherit'
    },
    documentPanel: {
        cursor: 'pointer',
        borderRadius: '5px',
        padding: '5px',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}));

const Article = () => {
    const { id } = useParams();
    const { data, isLoading } = useKnowledgeBank();
    const { data: fullArticle } = useKnowledgeBankArticle(id);

    const history = useHistory();

    console.log({fullArticle});

    console.log({ isLoading, data });

    console.log({ id, parsed: parseInt(id) });   

    const article = useMemo(() => data?.articles.find(a => a.id === parseInt(id)), [data?.articles, id]);

    console.log({ article });

    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink)
            return;

        e.preventDefault();

        let href = e.target.getAttribute("href");
        if (href.startsWith("/document")) {
            let id = parseInt(href.replace("/document/", ""));
            downloadDocumentById(id);
        } else if (href.startsWith("/")) {
            history.push(href);
        } else {
            window.open(href, '_blank');
        }       
    };

    const classes = useStyles();
    const { Track, trackEvent } = useTracking({ page: `KnowledgeBank-Article:${id}`});    

    const categoryName = useCallback(id => data.subCategories.find(c => c.id === id).name, [data?.subCategories]);

    const relatedArticles = useMemo(() => data.articles.filter(a => fullArticle?.childArticleIds.includes(a.id)), [data.articles, fullArticle?.childArticleIds]);

    const documents = useMemo(() => data.documents.filter(a => fullArticle?.childDocumentIds.includes(a.id)), [data.documents, fullArticle?.childDocumentIds]);

    console.log({ documents });

    const downloadDocumentById = (id) => {
        let doc = data.documents.find(d => d.id === id);
        downloadDocument(doc);
    }

    const downloadDocument = async (doc) => {

        const response = await caseNestDocumentService.fetchDocument(doc.identifier, 'arraybuffer')
        let blob = new Blob([response], { type: doc.contentType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = doc.filename;
        link.click();

        trackEvent({ event: `${trackingConstants.events.downloadKnowledgeDocument}: ${doc.id}` });        
    }

    return (
        <PageBase>
            <Track>
                <Container>
                    <Box display="flex" flexDirection="column" gridGap="15px">
                        <Typography><span className={classes.title}>{categoryName(article.categoryId)}</span></Typography>
                        <Typography>{article.name}</Typography>
                        <Typography style={{ fontSize: '0.9em' }}>{article.summary}</Typography>
                        <Divider />
                        {!fullArticle &&
                            <Box display="flex" width="100%" justifyContent="center">
                                <CircularProgress />
                            </Box>}
                        {fullArticle &&
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <div dangerouslySetInnerHTML={{ __html: fullArticle.body }} onClick={contentClickHandler} />
                                </Grid>
                                <Grid item xs={4}>
                                    {documents.length === 0 && <Typography>No documents</Typography> }
                                    {documents.length > 0 &&
                                        <Box display="flex" flexDirection="column" gridGap="10px" style={{marginBottom: '20px'}}>
                                            <Typography>Documents</Typography>
                                            {documents.map(d =>
                                                <Box
                                                    className={classes.documentPanel}
                                                    display="flex"
                                                    alignItems="center"
                                                    gridGap="10px"
                                                    key={d.id}
                                                    onClick={() => downloadDocument(d)}
                                                >
                                                    <Box>{iconFromContentType(d.contentType)}</Box>
                                                    <Box>{d.name}</Box>
                                                </Box>
                                            )}
                                        </Box>   
                                    }
                                    {relatedArticles.length > 0 &&
                                        <Box display="flex" flexDirection="column" gridGap="10px">
                                            <Typography>Related Articles</Typography>
                                            {relatedArticles.map(a =>
                                                <ArticleTile key={a.id} article={a} />
                                            )}
                                        </Box>
                                    }                              
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Container>
            </Track>            
        </PageBase>
    );
}

export default Article;
