import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import knowledgeBankService from "../../services/knowledgeBankService";

const useKnowledgeBankArticle = (id) =>
    useQuery(
        [queryKeys.knowledgeBankArticle, id],
        () => knowledgeBankService.getArticle(id),
        {
            staleTime: Infinity
        }
    )

export default useKnowledgeBankArticle;
