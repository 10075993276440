import React from "react";
import { useRouteMatch } from "react-router";
import {
    Box,
    Breadcrumbs,
    CircularProgress,
    Link,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { sectionNameMap } from "../../constants/knowledgeHubNameMaps";
import usePrismicCategory from "../../hooks/queries/usePrismicCategory";
import usePrismicContentItem from "../../hooks/queries/usePrismicContentItem";

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
    },
}));

const KnowledgeHubBreadcrumbs = () => {
    const classes = useStyles();
    const { path, url, params } = useRouteMatch();
    const { sectionName, categoryId, itemType, itemId, cachePage } = params;
    const categoryQuery = usePrismicCategory(sectionName, categoryId);
    const contentItemQuery = usePrismicContentItem(
        sectionName,
        itemId,
        itemType,
        categoryId,
        cachePage
    );

    const urlSplit = url.split("/").filter((x) => x);
    const pathSplit = path.split("/").filter((x) => x);

    const isLoading = categoryQuery.isLoading || contentItemQuery.isLoading;

    if (isLoading)
        return (
            <Paper className={classes.breadcrumbs}>
                <Box className={classes.loadingContainer}>
                    <CircularProgress size={24} color="primary" />
                </Box>
            </Paper>
        );

    const getLabelForParam = (param) => {
        switch (param) {
            case ":sectionName":
                return sectionNameMap[params[param.substring(1)]];
            case ":categoryId":
                return categoryQuery.data?.title;
            case ":itemId":
                return contentItemQuery.data?.title;
            case ":searchTerm":
                return "[Search Results]"
            default:
                return "Broken Link";
        }
    };

    const mappedBreadcrumbs = pathSplit
        .map((value, index) => {
            if (
                value === "knowledgehub" ||
                value === ":itemType" ||
                value === ":cachePage" ||
                value === "search"
            )
                return null;

            const last = params.cachePage
                ? index === urlSplit.length - 2
                : index === urlSplit.length - 1;

            const to = last ? url : `/${urlSplit.slice(0, index + 1).join("/")}`;

            const label = getLabelForParam(value);

            return { to, label, last };
        })
        .filter((x) => x);

    return (
        <Paper className={classes.breadcrumbs}>
            <Breadcrumbs>
                <Link component={RouterLink} to="/">
                    Home
                </Link>
                {mappedBreadcrumbs.map((breadcrumb, index) =>
                    breadcrumb.last ? (
                        <Typography key={index} color="textPrimary">
                            {breadcrumb.label}
                        </Typography>
                    ) : (
                        <Link key={index} component={RouterLink} to={breadcrumb.to}>
                            {breadcrumb.label}
                        </Link>
                    )
                )}
            </Breadcrumbs>
        </Paper>
    );
};

export default KnowledgeHubBreadcrumbs;
